@font-face {
  font-family: 'payment';
  src: local('payment'), url('./fonts/Jost-VariableFont_wght.ttf') format('truetype');
  font-weight: normal;
}
@font-face {
  font-family: 'opensans';
  src: local('opensans'), url('./fonts/open-sans.regular.ttf') format('truetype');
  font-weight: normal;
}
@font-face {
  font-family: 'opensans';
  src: local('opensans'), url('./fonts/open-sans.semibold.ttf') format('truetype');
  font-weight: bold;
}
* {
  font-family: 'opensans';
  font-weight: normal;
}
a {
  text-decoration: none!important;
}
.paymentfont {
  font-family: 'payment'!important;
}
img {
  width: 100%;
}
.lightshadow {
  box-shadow: rgba(136, 165, 191, 0.48) 6px 2px 16px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px;
}
.globalimgsize {
  width: 150px!important;
  height: 150px!important;
  border-radius: 80px;
}
.flex {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.blockbg {
  background-color: #ff6e40;
}
.bggrey {
  background-color: #eeeeee;
}
.bggreen {
  background-color: #69f0ae;
}
.text-green {
  color: #69f0ae;
}
.commonshadow {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
}
.pointer {
  cursor: pointer;
}
.officialbg {
  background-color: #ef6c00;
}
.officialcolor {
  color: #ef6c00!important;
}
.lspace2 {
  letter-spacing: 2px;
}
.lspace3 {
  letter-spacing: 3px;
}
.lspace4 {
  letter-spacing: 4px;
}
.lspace5 {
  letter-spacing: 5px;
}
@media print {
  .pp {
    padding: 1.5em;
  }
}
.blur {
  background: rgba(0, 0, 0, 0.3);
	backdrop-filter: saturate(180%) blur(20px);	
}

/* SIDEBAR */

.content {
  margin-left: 230px;
}
@media(max-width: 768px){
  .content {
    width: 100%;
    margin-left: 0px;
  }
}
.sidebarIconColor {
  color: white;
}
.content-v2 {
  margin-left: 230px!important;
  margin-top: 10px;
}
@media(max-width: 768px){
  .content-v2{
    margin-left: 0!important;
    margin-bottom: 5em;
  }
}

/* HEADER */
.header {
  background: #212121;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

/* PAGES */
.landing-bg {
  height: 120vh;
  background-repeat: no-repeat;
  background-size: 100%;
  min-height: 100%;
  background-origin: padding-box;
}
@media(max-width: 992px){
  .landing-bg{
    height: 100vh;
    background-size: cover;
  }
}
.brand {
  text-align: left;
}
@media(max-width: 768px){
  .brand{
      text-align: center;
  }
}
.logo {
  width: 130px;
}
.bgGrey {
  background-color: #f5f5f5!important;
}
.bgOrange {
  background-color: #ef6c00!important;
}
.colorOrange {
  color: #ef6c00!important;
}
.print-landscape {
  @media print {
    @page {
      size: landscape!important;
      orientation: landscape!important;
    }
  }
}

@media print {
  .height-auto {
    height: auto!important;
  }
}

/* HEADER */
.navLink {
  text-align: end;
}
.navLink ul {
  margin-top: 20px;
}
.navLink ul li {
  display: inline;
  margin: 10px;
  font-size: 1.1em;
  color: white;
  padding: 0.3em;
}
.navLink a {
  color: white;
}
.navLink a:hover {
  color: black;
}

/* FOOTER */
.siteMap ul li {
  color: white;
}
@media(max-width: 768px){
  .footer {
      padding-bottom: 3em;
  }
}

/* HOME */
.landing{
  height: 90vh;
  background-attachment: fixed;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
}
.registeredText {
  border-radius: 10px;
  background-image: linear-gradient(120deg, #2ec4b6, #cbf3f0);
}
.tagline {
  font-size: 4em;
}
.homeCarousel {
  height: 89vh;
}
.homeCarousel .carousel-item {
  height: 89vh;
}
.homeCarousel img {
  object-fit: cover;
  height: 100%!important;
  opacity: 0.8;
}
.carouselTagline {
  height: 80vh;
}
.tagline {
  text-shadow: 3px 3px 20px #000000, -2px 1px 20px #000000;
}
.sub-tagline {
  text-shadow: 3px 3px 20px #000000, -2px 1px 20px #000000;
}
@media(max-width: 768px){
  .tagline {
      font-size: 2.5em;
  }
  .homeCarousel {
    height: 87vh;
  }
  .carouselTagline {
    height: 75vh;
  }
}

/* COURSES */
.courses {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
}


.report-table {
  font-size: 12px;
}